@import "./destination.css";

/* remove? */
.hidden {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000000000000001;
  background-color: black;
  opacity: 1;
  pointer-events: none;
}

.hidden-bot {
  position: absolute;
  left: -5000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.recaptcha-custom {
  position: relative;
  top: -150px;
  left: -7px;
}



.portrait-mirko {
  left: 200px;

}

canvas {
  display: block;
}

#waterTexture {
  z-index: 10000000000000002;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000000000;
}

* {
  cursor: none;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.spacer {
  display: none;
  min-height: 300px;
}

@-moz-document url-prefix() {
  .portfolio-color-bg {
    display: none;
}
}

.disable-select {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

.about-headline {
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 100;
  line-height: 1.1;
}

ul {
  list-style-type: circle inside url("./../../static/images/white-gradient.png");
}

/* .blood {
  color: #ff1245;
}
.red {
  color: #eb46c5;
} */

.blue {
  color: #3f9dd4;
}

.bolder {
  text-decoration: double;
}

.text {
  text-transform: uppercase;
  line-height: 1.5; 
}

/* safari viewport bug test */
.h-mobile-screen {
  height: calc(var(--vh, 1vh) * 100);
}

/************* GLOBAL RESET */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
}

/************* COLORS */
:root {
  --off-white: #c4c4c4;
  --dark: black;
}

/************* FONTS */
@font-face {
  font-family: "Dx Sitrus";
  src: url("../../static/fonts/DxSitrus-Expanded.woff") format("woff"),
    url("../../static/fonts/DxSitrus-Expanded.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.content-wrapper::-webkit-scrollbar,
.works-container::-webkit-scrollbar,
.contact-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.explore-button {
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Jost";
  text-transform: uppercase;
  font-size: 1em;
  color: white;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border-radius: 50%;
  background-color: black;
  border: 1px solid var(--off-white);
  opacity: 0.5;
  width: 10em;
  height: 10em;
  display: none;
}

.social-svg {
  width: 30px;
  height: 30px;
}

#cursor {
  /* mix-blend-mode: difference; */
  z-index: 99999999999999;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  will-change: transform;
}

#cursor .cursor--inner {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  /* bring back if this isn't the issue */
  transition: background-color 0.3s, width 0.3s, height 0.3s;
  border: solid 2px var(--off-white);
}

.cursor--active {
  color: var(--off-white);
  background-color: var(--off-white);
  width: 7vw;
  height: 7vh;
}

#circle {
  position: fixed;
  z-index: 5;
  width: 16px;
  height: 16px;
  background-color: var(--off-white);
  border-radius: 50%;
  pointer-events: none;
  /* promote it to its own layer to enable  hardware accelerated rendering: */
  transform: translate3d(0, 0, 0);
}

.cursor--active-menu {
  color: var(--off-white);
  background-color: var(--off-white);
  width: 7vw;
  height: 7vh;
}

.cursor--active-contact {
  color: var(--off-white);
  background-color: var(--off-white);
  width: 7vw;
  height: 7vh;
}

.cursor--active-instagram {
  color: var(--off-white);
  background-color: var(--off-white);
  width: 7vw;
  height: 7vh;
}

.cursor--active-twitter {
  color: var(--off-white);
  background-color: var(--off-white);
  width: 7vw;
  height: 7vh;
}

.cursor--active::before {
  content: "EXPLORE";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7vw;
  height: 7vh;
  text-align: center;
  font-size: clamp(0.5rem, 1rem, 24px);
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cursor--active-menu::before {
  content: "HOME";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7vw;
  height: 7vh;
  text-align: center;

  font-size: clamp(0.5rem, 1rem, 24px);
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cursor--active-contact::before {
  content: "SEND";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7vw;
  height: 7vh;
  text-align: center;
  font-size: clamp(0.5rem, 1rem, 24px);
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cursor--active-instagram::before {
  content: "VISIT";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7vw;
  height: 7vh;
  text-align: center;
  font-size: clamp(0.5rem, 1rem, 24px);
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cursor--active-twitter::before {
  content: "VISIT";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7vw;
  height: 7vh;
  text-align: center;
  font-size: clamp(0.5rem, 1rem, 24px);
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h1 {
  color: white;
  font-family: "Dx Sitrus";
}

.pseudo-pic-hover-tag {
  font-size: clamp(2rem, 30vw, 2.9375rem);
  color: #bebaba;
}

.gallery-headline {
  font-size: 9.375rem;
}

.gallery-footer-link-primary {
  font-size: 9.375rem;
}

.gallery-footer-link-secondary {
  font-size: 6.25rem;
}

.gallery-footer-link-primary:hover {
  font-size: 10.625rem;
}

.gallery-footer-link-secondary:hover {
  font-size: 10.625rem;
}

.pseudo-circle {
  font-size: 3.125rem;
}

.hello-button {
  font-size: 1rem;
}

.contact-footer {
  font-size: 1rem;
}

/************* GLOBAL ELEMENTS */
body {
  font-family: "Jost", sans-serif;
  background-color: var(--dark);
  width: 100vw;
  overflow: hidden;
}

p {
  font-size: 1rem;
  line-height: 2rem;
}

form {
  font-size: 1rem;
}

/************* MENU & CONTENT AREA */
.menu-font {
  /* text-shadow: 0 0 8px #cccfcabf; */
  position: absolute;
  z-index: 100;
  margin-bottom: 20px;
  bottom: 0;
  font-size: clamp(7.5rem, 12vw, 15.625rem);
  font-family: "Dx Sitrus";
  text-transform: uppercase;
  color: var(--off-white);
  writing-mode: vertical-rl;
  height: auto;
  opacity: 0.5;
}

.is-active {
  cursor: none;
}

.menu-icon {
  position: fixed;
  display: flex;
  top: 88px;
  right: 45px;
  width: 44px;
  height: null;
  mix-blend-mode: difference;
  z-index: 100000;
}

.menu-icon-circle {
  mix-blend-mode: difference;
  position: fixed;
  top: 30px;
  right: 30px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid var(--off-white);
  cursor: pointer;
  z-index: 10000000;
}

.menu-font-icon {
  mix-blend-mode: difference;
  position: absolute;
  border-top: 0px;
  border-left: 2px solid var(--off-white);
  color: var(--off-white);
  cursor: pointer;
  padding-right: 4px;
}

.menu-contact-icon {
  height: 38px;
}

.menu-works-icon,
.menu-about-icon {
  height: 32px;
}

.content-area {
  width: auto;
  height: 100vh;
  margin-left: calc(clamp(7.5rem, 12vw, 15.625rem) * 2);
  margin-right: calc(clamp(7.5rem, 12vw, 15.625rem));
  padding-bottom: 20px;
  padding-top: 20px;
  border-right: 1px solid var(--off-white);
}

.about-container,
.works-container,
.contact-container {
  width: 100%;
  height: 100vh;
  /* danger */
  /* display: none; */
}

.contact-container {
  height: 90vh;
}

/************* SIDE COLOR */
.side-color {
  transform: rotate(90deg);
  position: absolute;
  top: unset;
  left: 0;
  right: unset;
  bottom: 0;
  z-index: 110;
  mix-blend-mode: multiply;
  width: 35vw;
  pointer-events: none;
}

.color-bg {
  position: absolute;
  mix-blend-mode: multiply;
  z-index: 11;
  z-index: 999999999999999;
}

/************* ABOUT SECTION */
.about-container {
  width: auto;
  height: 100%;
}

.image-container {
  overflow: hidden;
  width: 60%;
  height: 100%;
}

.about-img-mask {
  /* display: none; */
  z-index: 10;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: var(--dark);
}

.portrait-mirko {
  object-fit: cover;
  object-position: 70%;
  height: 100%;
  /* danger */
  width: 30vw;
  /* opacity: 0; */
  /* width: 100%; */
}

.about-me-container {
  width: 60%;
  height: 100%;
  color: var(--off-white);
  padding: 0% 5% 20% 5%;
}

.about-me-header {
  font-size: 3em;
  margin-bottom: 1.5em;
  height: auto;
}

.about-me-p {
  margin-bottom: 2em;
}

.text-container {
  overflow: hidden;
}

/************* WORKS SECTION */
.works-container {
  justify-content: space-between;
  align-items: center;
}

.destination-wrapper {
  margin: 0 0.5em;
}

.works-wrapper {
  overflow: hidden;
  width: 33%;
  height: 100%;
  padding-bottom: 45px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;

}

.img {
  width: 100%;
  height: 100%;
}

.works-img-mask,
.marquee-mask {
  height: 100%;
  width: 800px;
  background-color: var(--dark);
}

.narrative-img {
  background-image: url("../../static/images/narrative/narrative10.jpg");
  background-size: cover;
  background-position: 70%;
}

.destination-img {
  background-image: url("../../static/images/destination-main.jpg");
  background-size: cover;
  background-position: center;
}

.portrait-img {
  background-image: url("../../static/images/narrative/narrative22.jpg");
  background-size: cover;
  background-position: center;
}

/* MARQUEE */
.works-text-container {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--dark);
  color: var(--off-white);
  font-size: 2rem;
  line-height: 2em;
  text-transform: uppercase;
  position: relative;
}

.text-box {
  width: auto;
  position: absolute;
  padding-left: 20px;
}

.narrative-box {
  left: -155px;
}

.destination-box {
  left: -209px;
}

.portrait-box {
  left: -151px;
}

/* CONTACT SECTION */

.mobile-button-wrapper {
  display: none;
}
.contact-container {
  opacity: 0;
  text-transform: uppercase;
  padding: 2em;
  display: none;
  flex-direction: column;
}

::placeholder {
  font-family: "Jost", sans-serif;
  text-transform: uppercase;
  color: var(--off-white);
}

input[type="text"],
input[type="email"] {
  border: none;
  border-bottom: 1px solid var(--off-white);
  padding: 10px 10px;
  background-color: transparent;
  width: calc(48%);
  color: white;
}

textarea {
  width: 100%;
  height: 350px;
  padding: 10px 10px;
  border: 1px solid var(--off-white);
  border-radius: 1px;
  background-color: transparent;
  resize: none;
  color: white;
}

#name {
  color: white;
  width: calc(50% -10px);
  display: inline-block;
  margin-right: 20px;
}

#email {
  color: white;
  width: 49%;
  display: inline-block;
}

#message {
  margin-top: 6em;
  width: 100%;
  display: block;
}

.contact-button {
  font-family: "Jost", sans-serif;
  text-transform: uppercase;
  background-color: var(--dark);
  border: 2px solid var(--off-white);
  height: 80px;
  width: 80px;
  border-radius: 50%;
  color: var(--off-white);
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  z-index: 20;
  position: absolute;
}

.button-wrapper {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
}

.button-color {
  display: none;
  z-index: 10;
  height: 6vh;
  width: 6vh;
  position: absolute;
  right: 0;
}

.button-white {
  transform: translateX(16%);
  z-index: 11;
  position: absolute;
  height: 120px;
  width: 120px;
  right: -60;

}

#name {
  border: none;
  border-bottom: 1px solid var(--off-white);
  padding: 10px 10px;
  background-color: transparent;
  width: calc(49% - 10px);
}

footer {
  display: flex;
  justify-content: space-between;
  margin-top: 4em;
}

.social-links-wrapper {
  bottom: 2em;
}

.legal-wrapper {
  color: var(--off-white);
  bottom: 2em;
  display: flex;
  align-items: start;
}

.social-links-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

}

.instagram-link {
  color: white;
  margin-right: 60px;
}

.linkedin-link {
  color: white;
  margin-right: 30px;
}

.twitter-link {
  color: white;
}

.pikomoto-link {
  color: white;
  margin-right: 30px;
}

.privacy-link {
  margin-bottom: 0px;
  margin-right: 30px;
}

/************* MEDIA QUERIES

/* Tablet */

@media only screen and (min-width: 1601px) {

  .portrait-mirko {
    object-position: 49% 100%;
  }
}

@media only screen and (min-width: 2100px) {

  .portrait-mirko {
    object-position: 70% 100%;
  }
}


@media only screen and (max-width: 1600px) {

  /* danger */
  .portrait-mirko {
    /* background-color: green; */
    width: 100%;
    left: 20px;
    max-height: 800px;
    /* background-color: #3f9dd4; */
  }

  .menu-font {
    position: absolute;
    z-index: 100;
    margin-bottom: 20px;
    bottom: 0;
    font-size: clamp(7.5rem, 12vw, 15.625rem);
    font-family: "Dx Sitrus";
    text-transform: uppercase;
    color: var(--off-white);
    writing-mode: vertical-rl;
    height: auto;
  }


.menu-icon {
  position: fixed;
  display: flex;
  top: 42px;
  right: 35px;
  width: 44px;
  height: null;
  mix-blend-mode: difference;
  z-index: 100000;
}




.menu-icon-circle {
  mix-blend-mode: difference;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid var(--off-white);
  cursor: pointer;
  z-index: 10000000;
}

.menu-font-icon {
  mix-blend-mode: difference;
  position: absolute;
  border-top: 0px;
  border-left: 2px solid var(--off-white);
  color: var(--off-white);
  cursor: pointer;
  padding-right: 4px;
}

.menu-contact-icon {
  height: 38px;
}

.menu-works-icon,
.menu-about-icon {
  height: 32px;
}

  .portrait-mirko {
    object-position: 70% 100%;
  }

  .side-color {
    z-index: 110;
    transform: rotate(180deg);
    right: unset;
    bottom: unset;
    left: 0;
    top: 0;
    width: 30vw;
  }

  #cursor .cursor--inner {
    width: 8vw;
    max-width: 80px;
    max-height: 80px;
    height: 8vw;
  }

  .narrative-text-top,
  .destination-text-top,
  .portrait-text-top {
  }

  .narrative-text-bottom,
  .destination-text-bottom,
  .portrait-text-bottom {
  }

  .menu-font {
    font-size: clamp(7.5rem, 12vw, 10.625rem);
    top: 0;
    padding-top: 20px;
    bottom: auto;
  }

  .gallery-headline {
    font-size: clamp(6.25rem, 82vw, 9.375rem);
  }

  .gallery-footer-link-primary {
    font-size: clamp(4.6875rem, 70vw, 7.5rem);
  }

  .gallery-footer-link-secondary {
    font-size: clamp(3rem, 45vw, 5rem);
  }

  .pseudo-circle {
    font-size: clamp(2rem, 65vw, 3.125rem);
  }

  .pseudo-pic-hover-tag {
    font-size: 2.5rem;
  }

  body {
    background-color: var(--dark);
    width: auto;
  }

  .about,
  .works,
  .contact {
    position: fixed;
  }

  .about-container,
  .works-container,
  .contact-container {
    flex-direction: column;
  }

  .about-container,
  .works-container {
    overflow: scroll;
  }

  .about-me-container {
    width: 100%;
    padding: 10% 5% 20% 5%;
  }

  .image-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 800px;
  }

  /* TABLET WORKS SECTION */

  .destination-wrapper {
    margin: 0 0;
  }

  .works-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 800px;
  }

  .img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .works-img-mask,
  .marquee-mask {
    z-index: 0;
    width: 100%;
    position: absolute;
    background-color: var(--dark);
  }

  /* MARQUEE */
  .works-text-container {
    overflow: hidden;
    width: 100%;
    height: 10%;
    background-color: var(--dark);
    color: var(--off-white);
    font-size: 2rem;
    line-height: 2em;
    text-transform: uppercase;
    position: relative;
  }

  .text-box {
    width: auto;
    position: absolute;
    padding-left: 20px;
  }

  .narrative-box {
    left: -155px;
  }

  .destination-box {
    left: -209px;
  }

  .portrait-box {
    left: -151px;
  }
}

/* MEDIA QUERY MOBILE */
@media only screen and (max-width: 950px) {

  .recaptcha-custom {
    top: 100px;
    left: -0px;
  }

  .narrative-img {
    background-image: url("../../static/images/mobile/narrative10-min.jpg");
    background-size: cover;
    background-position: 70%;
  }
  
  .destination-img {
    background-image: url("../../static/images/mobile/destination-main-min.jpg");
    background-size: cover;
    background-position: center;
  }
  
  .portrait-img {
    background-image: url("../../static/images/mobile/narrative22-min.jpg");
    background-size: cover;
    background-position: center;
  }

  .contact-container {
    overflow: scroll;
  }

  .spacer {
    display: block;
  }

  .contact-container {
    padding: 1em;
  }

  .content-area {
    overflow-y: hidden;
  }


  .works-img-mask {
    display: none;
  }

  .about-img-mask {
    display: none;
  }
  
  .bottomText {
    padding-bottom: 10em;
  }


  .menu-icon {
    position: fixed;
    display: flex;
    top: 40px;
    right: 45px;
    height: 20px;
    width: 20px;
    mix-blend-mode: difference;
    z-index: 100000;
  }

  .menu-icon-circle {
    top: 25px;
    right: 25px;
    width: 50px;
    height: 50px;
  }

  .menu-contact-icon {
    width: 28px;
    height: 5px;
    order: 1
  }
  
  .menu-works-icon,
  .menu-about-icon {
    height: 2px;
    width: 24px;
  }



  .menu-font-icon {
    mix-blend-mode: difference;
    position: absolute;
    border-top: 2px solid var(--off-white);
    border-left: 0;
    color: var(--off-white);
    cursor: pointer;
  }

  /* test */
  .about-image-mask {
    max-height: 900px;
  }

  .narrative-text-top,
  .destination-text-top,
  .portrait-text-top {
    border-top: 1px solid var(--off-white);
  }

  .narrative-text-bottom,
  .destination-text-bottom,
  .portrait-text-bottom {
    border-bottom: 1px solid var(--off-white);
  }



  .image-container {
    min-height: 700px;
  }

  .portrait-mirko {
    object-position: 70% 95%;
    max-height: 660px;
  }


  .img {
    height: 77%;
  }

  .works-wrapper {
    min-height: 800px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .works-text-container {
    height: 7%;
  }

  .about-container {
    height: 100%;
  }

  .works-container {
    height: 100%;
  }

    #cursor .cursor--inner {
      dispay: none;
  }

  .cursor--active::before {
    content: "EXPLORE";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6vw;
    height: 6vh;
    text-align: center;
    font-size: 0.7rem;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .menu-font {
    z-index: 1000;
    writing-mode: horizontal-tb;
    font-size: clamp(1.625rem, 10vw, 4.25rem);
    bottom: auto;
    top: auto;
    padding-left: 2%;
  }

  .gallery-headline {
    font-size: clamp(3.15rem, 80vw, 6.25rem);
  }

  .gallery-footer-link-primary {
    font-size: clamp (1.1875rem, 70vw, 4.6875rem);
  }

  .gallery-footer-link-secondary {
    font-size: clamp(1.1875rem, 50vw, 3rem);
  }

  .gallery-footer-link-primary:hover {
    font-size: clamp(2.125rem, 70vw, 5.375rem);
  }

  .gallery-footer-link-secondary:hover {
    font-size: clamp(2.125rem, 70vw, 5.375rem);
  }

  .pseudo-circle {
    font-size: clamp(0.5rem, 65vw, 2rem);
  }

  .pseudo-pic-hover-tag {
    font-size: clamp(1.1875rem, 65vw, 2.5rem);
  }

  form {
    font-size: clamp(0.75rem, 65vw, 1rem);
    overflow: hidden;
  }

  .contact-footer {
    font-size: clamp(0.75rem, 65vw, 1rem);
  }

  .hello-button {
    font-size: clamp(0.75rem, 65vw, 1rem);
  }

  .content-area {
    height: 100vh;
    margin-top: 2%;
    margin-left: 2%;
    margin-right: 2%;
    padding-top: calc(clamp(2.625rem, 10vw, 4.25rem) * 3);
    border-right: none;
  }

  .contact-area {
    height: 90vh;
  }

  .menu-font{
    padding-left: 1rem;
  }

  #name {
    border: none;
    border-bottom: 1px solid var(--off-white);
    padding: 10px 10px;
    background-color: transparent;
    width: calc(49% - 10px);
  }

  textarea {
    height: auto;
    padding: 10px 10px;
    border: 1px solid var(--off-white);
    border-radius: 1px;
    background-color: transparent;
    resize: none;
  }

  .narrative-img {
    background-image: url("../../static/images/mobile/narrative10-min.jpg");
    background-size: cover;
    background-position: 70%;
  }
  
  .destination-img {
    background-image: url("../../static/images/mobile/destination-main-min.jpg");
    background-size: cover;
    background-position: center;
  }
  
  .portrait-img {
    background-image: url("../../static/images/mobile/narrative22-min.jpg");
    background-size: cover;
    background-position: center;
  }

  .side-color {
    z-index: 110;
    transform: rotate(180deg);
    right: unset;
    bottom: unset;
    left: 0;
    top: 0;
    width: 30vw;
    z-index: 10000;
  }

  footer {
    flex-direction: row;
    min-height: 100px;
    margin-top: 2em;
    margin-bottom: 6em;
  }
}

@media only screen and (max-width: 600px) {

  .recaptcha-custom {
    top: 100px;
    left: -0px;
  }

  .menu-font {
    opacity: 1;
  }
  .explore-button {
    display: flex;
  }

  .narrative-img {
    background-image: url("../../static/images/mobile/narrative10-min.jpg");
    background-size: cover;
    background-position: 70%;
  }
  
  .destination-img {
    background-image: url("../../static/images/mobile/destination-main-min.jpg");
    background-size: cover;
    background-position: center;
  }
  
  .portrait-img {
    background-image: url("../../static/images/mobile/narrative22-min.jpg");
    background-size: cover;
    background-position: center;
  }

  .menu-icon-circle {
    width: 40px;
    height: 39px;
    right: 20px;
    top: 20px;
  }

  .menu-icon {
    width: 22px;
    height: 18px;
    right: 29px;
    top: 31px;
  }

  .menu-contact-icon {
    width: 22px;
  }

  .menu-works-icon,
  .menu-about-icon {
    width: 18px;
  }

   .menu-font-icon {
    border-top: 1px solid white;
  } 

  #name {
    width: 100%;
  }

  #email {
    width: 100%;
  }

  #message {
    margin-top: 4em;
  }

  .image-container {
    min-height: 400px;
  }

  .portrait-mirko {
    object-position: 70% 95%;
    max-height: 360px;
  }

  .about-container {
    height: 100%;
  }

  .works-container {
    height: 100%;
  }

  .portrait-mirko {
    height: 900px;
    width: 100%;
  }

  * { cursor: default; }

  .cursor--active {
    display: none;
  }

  #cursor {
    display: none;
  }

  #circle{
    display: none;
  }

  .cursor--inner {
    display: none;
  }

  .content-area {
    min-height: -webkit-fill-available;
  }

  .cursor--active::before {
    font-size: 0px;
  }

  footer {
    display: flex;
    align-items: center;
  }

  .mobile-button-wrapper {
    display: none;
    align-self: center;
    margin: auto;
    float: none;
    margin-bottom: 4em;
  }

  .mobile-contact-button {
    font-family: "Jost", sans-serif;
    text-transform: uppercase;
    background-color: var(--dark);
    border: 2px solid var(--off-white);
    height: 160px;
    width: 160px;
    border-radius: 50%;
    color: var(--off-white);
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    z-index: 20;
  }

  .social-links-wrapper {
    display: flex;
  }

  .instagram-link {
    color: white;
  }

  .linkedin-link {
    color: white;
    margin-bottom: 2em;
  }

  .twitter-link {
    color: white;
  }
}

@media only screen and (max-width: 450px) {

  .recaptcha-custom {
    top: 100px;
    left: -0px;
  }

  .contact-container {
    overflow-x: hidden;
    height: 100vh;
  }

  .content-container {
    padding-bottom: 40%;
  }

  .works-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 380px;
  }

  .side-color {
    width: 40vw;
    display: none;
  }

  .about-headline {
    font-size: 2.25rem;
    text-transform: uppercase;
  }

}


