html.has-scroll-smooth {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
}

html, body {
  overflow-x: hidden;
  overflow: hidden;
}

.destination-headline, 
.destination-headline-mobile {
  text-transform: uppercase;
}

.menu-font-icon-horizontal {
  z-index: 10;
  top: 70px;
  right: 45px;
  position: fixed;
  height: 1px;
  width: 50px;
  background-color: white;
  display: block;
}

* {
  cursor: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.destination-headline-mobile {
  font-size: clamp(3.5rem, 12vw, 15.625rem);
  font-family: "Dx Sitrus";
  text-align: center;
  display: none;
}

#preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 10000000000000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

figure {
  display: flex;
}

.fig1 {
  position: relative;
  z-index: 10000;
  left: -10px;
  display: inline;
  width: 20px;
  writing-mode: vertical-rl;
  color: var(--off-white);
}

.portfolio-color-bg {
  position: absolute;
  width: 140vh;
  height: 140vh;
  mix-blend-mode: multiply;
  z-index: 11;
  pointer-events: none;
}

#circle {
  position: fixed;
  z-index: 5;
  width: 16px;
  height: 16px;
  background-color: var(--off-white);
  border-radius: 50%;
  pointer-events: none;
  /* promote it to its own layer to enable  hardware accelerated rendering: */
  transform: translate3d(0, 0, 0);
}

/* removes the default link color which is blue... */
a {
  color: inherit;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}
[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}
[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}

.destination-bg {
  overflow-y: scroll;
}

.destination-headline-container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: grid;
  justify-content: center;
  align-content: center;
  color: white;
}

.destination-headline {
  font-size: clamp(3.5rem, 12vw, 15.625rem);
  font-family: "Dx Sitrus";
  text-align: center;
}

.closer {
  position: relative;
}

.closer-headline {
  position: absolute;
  font-family: "Dx Sitrus";
  font-weight: 100;
  font-size: clamp(3.5rem, 12vw, 15.625rem);
}

.closer-headline-1 {
  margin-left: 10px;
  bottom: 50vh;
  left: 10vw;
}

.closer-headline-2 {
  top: 50vh;
  right: 10vw;
}

.narrative-deco-mobile::before {
  position: absolute;
  right: 20vw;
  transform: translateY(110%);
  display: flex;
  content: "01";
  width: 2.5em;
  height: 2.5em;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: .5em;
  border: 1px solid white;
  border-radius: 50%;
  opacity: 0.2;
}

.narrative-deco::before {
  position: absolute;
  right: 25vw;
  transform: translateY(-45%);
  display: flex;
  content: "01";
  width: 2.5em;
  height: 2.5em;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: .3em;
  border: 1px solid white;
  border-radius: 50%;
  opacity: 0.3;
}

.destination-deco-mobile::before {
  position: absolute;
  right: 20vw;
  transform: translateY(110%);
  display: flex;
  content: "02";
  width: 2.5em;
  height: 2.5em;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: .5em;
  border: 1px solid white;
  border-radius: 50%;
  opacity: 0.2;
}

.destination-deco::before {
  position: absolute;
  right: 24vw;
  transform: translateY(-45%);
  display: flex;
  content: "02";
  width: 2.5em;
  height: 2.5em;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: .3em;
  border: 1px solid white;
  border-radius: 50%;
  opacity: 0.3;
}

.shader-deco-mobile::before {
  position: absolute;
  right: 20vw;
  transform: translateY(120%);
  display: flex;
  content: "03";
  width: 2.5em;
  height: 2.5em;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: .5em;
  border: 1px solid white;
  border-radius: 50%;
  opacity: 0.2;
}

.shader-deco::before {
  position: absolute;
  right: 19vw;
  transform: translateY(-25%);
  display: flex;
  content: "03";
  width: 2.5em;
  height: 2.5em;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: .3em;
  border: 1px solid white;
  border-radius: 50%;
  opacity: 0.3;
}

.closer-headline-1::before {
  position: absolute;
  top: 2em;
  left: 1em;
  display: flex;
  content: "02";
  width: 2.5em;
  height: 2.5em;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.3em;
  border: 1px solid white;
  border-radius: 50%;
  opacity: 0.5;
}

.closer-headline-2::after {
  position: absolute;
  top: -0.6em;
  right: 1em;
  display: flex;
  content: "03";
  width: 2.5em;
  height: 2.5em;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.3em;
  border: 1px solid white;
  border-radius: 50%;
  opacity: 0.5;
}

.closer-container {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.destination-subheading {
  font-family: "Jost";
  text-transform: uppercase;
  line-height: 1.5em;
  text-align: center;
  margin: 2em auto;
  max-width: 600px;
}

.scroll-indicator {
  position: absolute;
  text-transform: uppercase;
  bottom: 130px;
  left: 50%;
  transform: translate(-50%);
}

.destination-hero-image {
  margin: auto;
  object-fit: cover;
  height: 80vh;
  width: 100vw;
}

.destination-content-container {
  position: relative;
  top: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 6em;
  margin: auto;
}

.destination-image {
  opacity: 0;
}

.closer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  margin-right: 0;
  margin-bottom: 100vh;
  background-color: black;
  color: white;
}

.closer-headline {
  z-index: 100;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.closer-headline:hover {
  letter-spacing: 4px;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  opacity: 1;
}

.closer-headline:not(:hover) {
  letter-spacing: 0px;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  opacity: 0.3;
}

.image-card {
  background-color: blue;
}

.destination-image-container {
  width: 90vw;
  height: auto;
  margin: auto;
}

.destination-image {
  width: 90vw;
  height: auto;
  margin: auto;
  object-fit: cover;
}

.image-header {
  font-weight: bold;
  text-transform: uppercase;
  margin: 10px 0px;
}

#container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.outro-image-container {
  display: block;
  position: absolute;
  object-fit: cover;
  width: 30vw;
}

.outro-mask {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
}

.outro-image {
  padding: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
}

@media only screen and (min-width: 950px) {

  .menu-font-icon-horizontal {
    z-index: 10;
    top: 60px;
    right: 35px;
    position: fixed;
    height: 1px;
    width: 50px;
    background-color: white;
    display: block;
  }

  .portfolio-color-bg {
    position: absolute;
    width: 140vh;
    height: 140vh;
    mix-blend-mode: multiply;
    z-index: 11;
    pointer-events: none;
  }

  .closer-deco-2 {
    bottom: 45vh;
    left: 15vh;
  }

  .closer-deco-3 {
    top: 48vh;
    right: 22vh;
  }

  .destination-headline-container {
    height: 100vh;
    width: 100vw;
  }

  .destination-image-container {
    width: auto;
    height: 80vh;
    margin: auto;
  }

  .destination-image {
    width: auto;
    height: 80vh;
    margin: auto;
  }

  .destination-content-container {
    top: 0vh;
    left: 100vw;

    width: max-content;
    height: 100vh;
    flex-direction: row;
  }

  .closer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin-right: 100vw;
    height: 100vh;
    background-color: black;
    flex-direction: column;
  }

  .outro-image-1-container {
    margin-bottom: 20px;
    margin-top: 20px;
    left: 15vw;
    top: 0;
    bottom: 0;
  }

  .outro-image-2-container {
    margin-bottom: 20px;
    margin-top: 20px;
    top: 0;
    right: 15vw;
    bottom: 0;
  }

  .outro-mask {
    z-index: 1;
  }

  .outro-image {
    padding: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .closer-headline {
    font-size: clamp(3.5rem, 12vw, 15.625rem);
  }

}

@media only screen and (min-width: 1600px) {
  .menu-font-icon-horizontal {
    z-index: 10;
    top: 70px;
    right: 45px;
    position: fixed;
    height: 1px;
    width: 50px;
    background-color: white;
    display: block;
  }
}

@media only screen and (max-width: 950px) {
  .menu-font-icon-horizontal {
    z-index: 10;
    top: 35px;
    right: 50px;
    position: fixed;
    width: 1px;
    height: 30px;
    background-color: white;
    display: block;
  }


  figure {
    position: relative;
  }

  .fig1 {
    position: absolute;
    z-index: 10000;
    left: 5px;
    bottom: -20px;
    display: inline;
    width: auto;
    writing-mode: horizontal-tb;
    color: var(--off-white);
  }

  .destination-subheading {
    max-width: 400px;
  }

  .outro-image-container {
    display: none;
  }

  .portfolio-color-bg {
    position: absolute;
    width: 70vh;
    height: 70vh;
    mix-blend-mode: multiply;
    z-index: 11;
    pointer-events: none;
  }
}

@media only screen and (max-width: 600px) {

  .closer-headline-1 {
    margin-left: 0;
    bottom: 50vh;
    left: 2vw;
  }
  
  .closer-headline-2 {
    top: 50vh;
    right: 2vw;
  }

  .menu-font-icon-horizontal {
    z-index: 10;
    top: 29px;
    right: 40px;
    position: fixed;
    width: 1px;
    height: 22px;
    background-color: white;
    display: block;
  }

  .destination-headline {
    display: none;
  }

.destination-headline-mobile {
  display: block;
  font-size: clamp(3.5rem, 20vw, 8.625rem);;
}

}

@media only screen and (max-width: 450px) {
  .menu-icon {
    right: 1rem;
  }

  .outro-image-container {
    display: none;
  }

  .closer-headline {
    font-size: clamp(3.5rem, 12vw, 8.625rem);
  }

  .destination-subheading {
    margin: 2em auto;
    max-width: 290px;
  }

  .destination-image-container {
    width: 95vw;
    height: auto;
  }

  .destination-image {
    width: 95vw;
    height: auto;
  }
}

@media only screen and (max-width: 300px) {
  .menu-icon {
    right: 1rem;
  }
}
