@import url('grid_12-825-55-15.css');

/*  
	CSS Reset by Eric Meyer - Released under Public Domain
    http://meyerweb.com/eric/tools/css/reset/
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, 
caption, tbody, tfoot, thead, tr, th, td 
                  {margin: 0;padding: 0;border: 0;outline: 0;
                  font-size: 100%;vertical-align: baseline;
                  background: transparent;}
body              {line-height: 1;}
ol, ul            {list-style: none;}
blockquote, q     {quotes: none;}
blockquote:before, blockquote:after,
q:before, q:after {content: '';	content: none;}
:focus            {outline: 0;}
ins               {text-decoration: none;}
del               {text-decoration: line-through;}
table             {border-collapse: collapse;border-spacing: 0;}




body {
	color: #000;
	background-color: #dcdcdc;
}

a {
	text-decoration: none;
	color: #1883ba;
}

h1{
	font-size: 32px;
	font-weight: normal;
	font-style: normal;
	margin-bottom: 18px;
}

h2{
	font-size: 18px;
}

#container {
	width: 865px;
	margin: 0px auto;
}


#header {
	padding: 20px;
	font-size: 36px;
	background-color: #000;
	color: #fff;
}

#header span {
	color: #666;
}
#main_content {
	background-color: #fff;
	padding: 60px 20px 20px;
}


#footer p {
	margin: 0;
	padding-top: 10px;
	padding-bottom: 50px;
	color: #333;
	font: 10px Arial, sans-serif;
}

.tabs {
	width: 100%;
	height: 31px;
	background-color: #444;
}
.tabs li {
	float:  left;
	margin: 0;
	overflow: hidden;
	background-color: #444;
}
.tabs li a {
	display: block;
	color: #fff;
	text-decoration: none;
	font: bold 11px/11px 'Arial';
	text-transform: uppercase;
	padding: 10px 15px;
	border-right: 1px solid #fff;
}

.tabs li a:hover {
		background-color: #00b3ff;

}

.tabs li.active a {
	color:  #000;
	background-color: #fff;
}



div.huge {
	
	font-size: 300px;
	line-height: 1em;
	padding: 0;
	letter-spacing: -.02em;
	overflow: hidden;
}
div.glyph_range {
	font-size: 72px;
	line-height: 1.1em;
}

.size10{ font-size: 10px; }
.size11{ font-size: 11px; }
.size12{ font-size: 12px; }
.size13{ font-size: 13px; }
.size14{ font-size: 14px; }
.size16{ font-size: 16px; }
.size18{ font-size: 18px; }
.size20{ font-size: 20px; }
.size24{ font-size: 24px; }
.size30{ font-size: 30px; }
.size36{ font-size: 36px; }
.size48{ font-size: 48px; }
.size60{ font-size: 60px; }
.size72{ font-size: 72px; }
.size90{ font-size: 90px; }


.psample_row1 {	height: 120px;}
.psample_row1 {	height: 120px;}
.psample_row2 {	height: 160px;}
.psample_row3 {	height: 160px;}
.psample_row4 {	height: 160px;}

.psample {
	overflow: hidden;
	position: relative;
}
.psample p {
	line-height: 1.3em;
	display: block;
	overflow: hidden;
	margin: 0;
}

.psample span {
	margin-right: .5em;
}

.white_blend {
	width: 100%;
	height: 61px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAVkAAAA9CAYAAAAH4BojAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNrs3TsKgFAMRUE/eer+NxztxMYuEWQG3ECKwwUF58ycAKixOAGAyAKILAAiCyCyACILgMgCiCyAyAIgsgAiCyCyAIgsgMgCiCwAIgsgsgAiC4DIAogsACIL0CWuZ3UGgLrIhjMA1EV2OAOAJQtgyQLwjOzmDAAiCyCyAIgsQFtkd2cAEFkAkQVAZAHaIns4A4AlC2DJAiCyACILILIAiCzAV5H1dQGAJQsgsgCILIDIAvwisl58AViyAJYsACILILIAIgvAe2T9EhxAZAFEFgCRBeiL7HAGgLrIhjMAWLIAliwAt1OAAQDwygTBulLIlQAAAABJRU5ErkJggg==);
	position: absolute;
	bottom: 0;
}
.black_blend {
	width: 100%;
	height: 61px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAVkAAAA9CAYAAAAH4BojAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPJJREFUeNrs3TEKhTAQRVGjibr/9QoxhY2N3Ywo50A28IrLwP9g6b1PAMSYTQAgsgAiC4DIAogsgMgCILIAIgsgsgCILIDIAogsACILILIAIguAyAKILIDIAiCyACILgMgCZCnjLWYAiFGvB0BQZJsZAFyyAC5ZAO6RXc0AILIAIguAyAKkRXYzA4DIAogsACILkBbZ3QwALlkAlywAIgsgsgAiC4DIArwVWf8uAHDJAogsACILILIAv4isH74AXLIALlkARBZAZAFEFoDnyPokOIDIAogsACILkBfZZgaAuMhWMwC4ZAE+p4x3mAEgxinAAJ+XBbPWGkwAAAAAAElFTkSuQmCC);
	position: absolute;
	bottom: 0;
}
.fullreverse {
	background:  #000 !important;
	color:  #fff !important;
	margin-left: -20px;
	padding-left: 20px;
	margin-right: -20px;
	padding-right: 20px;
	padding: 20px;
	margin-bottom:0;
}


.sample_table td {
	padding-top: 3px;
	padding-bottom:5px;
	padding-left: 5px;
	vertical-align: middle;
	line-height: 1.2em;
}

.sample_table td:first-child {
	background-color: #eee;
	text-align: right;
	padding-right: 5px;
	padding-left: 0;
	padding: 5px;
	font: 11px/12px "Courier New", Courier, mono;
}

code {
	white-space: pre;
	background-color: #eee;
	display: block;
	padding: 10px;
	margin-bottom: 18px;
	overflow: auto;
}


.bottom,.last 	{margin-bottom:0 !important; padding-bottom:0 !important;}

.box  { 
  padding: 18px; 
  margin-bottom: 18px; 
  background: #eee; 
}

.reverse,.reversed { background:  #000 !important;color:  #fff !important; border: none !important;}

#bodycomparison {
	position: relative;
	overflow: hidden;
	font-size: 72px;
	height: 90px;
	white-space: nowrap;
}

#bodycomparison div{
	font-size: 72px;
	line-height: 90px;
	display: inline;
	margin: 0 15px 0 0;
	padding: 0;
}

#bodycomparison div span{
	font: 10px Arial;
	position: absolute;
	left: 0;
}
#xheight {
	float: none;
	position: absolute;
	color: #d9f3ff;
	font-size: 72px;
	line-height: 90px;
}

.fontbody {
 position: relative;
}
.arialbody{
	font-family: Arial;
	position: relative;
}
.verdanabody{
	font-family: Verdana;
	position: relative;
}
.georgiabody{
	font-family: Georgia;
	position: relative;
}

/* @group Layout page
 */

#layout h1 {
	font-size: 36px;
	line-height: 42px;
	font-weight: normal;
	font-style: normal;
}

#layout h2 {
	font-size: 24px;
	line-height: 23px;
	font-weight: normal;
	font-style: normal;
}

#layout h3 {
	font-size: 22px;
	line-height: 1.4em;
	margin-top: 1em;
	font-weight: normal;
	font-style: normal;
}


#layout p.byline {
	font-size: 12px;
	margin-top: 18px;
	line-height: 12px;
	margin-bottom: 0;
}
#layout p {
	font-size: 14px;
	line-height: 21px;
	margin-bottom: .5em;
}

#layout p.large{
	font-size: 18px;
	line-height: 26px;
}

#layout .sidebar p{
	font-size: 12px;
	line-height: 1.4em;
}

#layout p.caption {
	font-size: 10px;
	margin-top: -16px;
	margin-bottom: 18px;
}

/* @end */

/* @group Glyphs */

#glyph_chart div{
	background-color: #d9f3ff;
	color: black;
	float: left;
	font-size: 36px;
	height: 1.2em;
	line-height: 1.2em;
	margin-bottom: 1px;
	margin-right: 1px;
	text-align: center;
	width: 1.2em;
	position: relative;
	padding: .6em .2em .2em;
}

#glyph_chart div p {
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	text-align: center;
	font: bold 9px Arial, sans-serif;
	background-color: #3a768f;
	width: 100%;
	color: #fff;
	padding: 2px 0;
}


#glyphs h1 {
	font-family: Arial, sans-serif;
}
/* @end */

/* @group Installing */

#installing {
	font: 13px Arial, sans-serif;
}

#installing p,
#glyphs p{
	line-height: 1.2em;
	margin-bottom: 18px;
	font: 13px Arial, sans-serif;
}



#installing h3{
	font-size: 15px;
	margin-top: 18px;
}

/* @end */

#rendering h1 {
	font-family: Arial, sans-serif;
}
.render_table td {
	font: 11px "Courier New", Courier, mono;
	vertical-align: middle;
}


